import React from "react"
import { Helmet } from "react-helmet"
import { graphql, PageProps, withPrefix } from "gatsby"

import { Embellishment13, Embellishment11 } from "../components/Embellishments"
import Layout from "../components/Layout"
import Image from "../components/Image"

import jobsDataJson from "../components/data/jobs-data.json"

import "../css/jobs.css"

type Location = string | { name: string }

function formatLocation(location: Location): string {
  return typeof location === "string" ? location : location.name
}

type JobProps = {
  title: string
  project: string
  location: string | { name: string }
  salary: string
  description: string
  applicationUrl: string
}

function Job(props: JobProps) {
  const { title, project, location, salary, description, applicationUrl } =
    props
  return (
    <li key={`${project}-${title}`}>
      <h4>{title}</h4>
      <p className="location">{formatLocation(location)}</p>
      {salary && salary.trim() && <p className="salary">{salary}</p>}
      <p className="description">{description}</p>
      <a className="apply" href={applicationUrl}>
        Apply
      </a>
    </li>
  )
}

type Project = {
  name: string
  logo: {
    image: string
    alt: string
  }
  jobs: JobProps[] | null | undefined
}

type ReasonsToJoinSectionProps = {
  hero: {
    title: string
  }
  projects: Project[] | null | undefined
  allJobs: {
    html?: string
  }
}

function ReasonsToJoinSection(props: ReasonsToJoinSectionProps) {
  const { hero, projects, allJobs } = props
  return (
    <div className="section overflow">
      <div className="working-at-thesis-header xl---padding-12---1-col">
        <div className="xl---v-padding---3---10-col">
          <h1 className="h1">{hero.title}</h1>
          <Embellishment11 />
          <Embellishment13 />
        </div>
      </div>
      <div className="header-photo" />
      <div className="xl---padding-12---1-col border---top background---white text---black position---relative">
        <div className="xl---v-padding---3---10-col projects">
          <div>
          <h3 style={{marginBottom: "50px", marginTop: "50px" }}>We're radically committed to decentralization, autonomy, and the happiness of our team. For more on what drives us, how we work, our benefits, and our hiring process, visit <a href="https://coda.io/d/draft-Working-Thesis_d9PKw6fTuaC/Working-Thesis-Zhuzh-0_suyVo5dk#_luXhO-dq" target="_blank">Working at Thesis</a>!</h3>
          </div>
          <div className="w-clearfix flex-wrap-columns">
            {Object.entries(jobsDataJson).map(([sectionId, data]) => {
              const sectionName = sectionId
                .split("-")
                .map(
                  (part) => part.slice(0, 1).toLocaleUpperCase() + part.slice(1)
                )
                .join(" ")

              return (
                <section key={`item-${sectionId}`} className="project">
                  <h3 className="h3">
                    Jobs at
                    <img
                      alt={sectionName}
                      src={withPrefix(`images/${sectionId}-jobs-logo@2x.png`)}
                      className="spacing---sm"
                    />
                  </h3>
                  <ul className="jobs">
                    {data.length ? (
                      data.map((job) => (
                        <Job
                          title={job.title}
                          project={sectionName}
                          salary=""
                          location={job.location}
                          description={job.metadata[0].value}
                          applicationUrl={job.absolute_url}
                        />
                      ))
                    ) : (
                      <h4>No jobs available</h4>
                    )}
                  </ul>
                </section>
              )
            })}
            {(projects ?? []).map(({ name: projectName, logo, jobs }) => (
              <section key={`item-${projectName}`} className="project">
                <h3 className="h3">
                  Jobs at{" "}
                  <Image
                    alt={projectName}
                    imageData={logo}
                    className="spacing---sm"
                  />
                </h3>
                <ul className="jobs">
                  {(jobs ?? []).length > 0 ? (
                    jobs.map((d) => ({ ...d, project: projectName })).map(Job)
                  ) : (
                    <h4>No jobs available</h4>
                  )}
                </ul>
              </section>
            ))}
            <div className="_2up-column" />
            <div className="_3up-column">
              <p
                className="contact-jobs"
                dangerouslySetInnerHTML={{ __html: allJobs.html }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

type JobsPageDataProps = {
  markdownRemark: {
    frontmatter: ReasonsToJoinSectionProps & { title: string }
  }
}

function JobsPage({ data }: PageProps<JobsPageDataProps>) {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <Helmet>
        <title>{post.frontmatter.title}</title>
      </Helmet>
      <ReasonsToJoinSection
        hero={post.frontmatter.hero}
        projects={post.frontmatter.projects}
        allJobs={post.frontmatter.allJobs}
      />
    </Layout>
  )
}

export default JobsPage

export const query = graphql`
  query JobsPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        title
        hero {
          title
        }
        projects {
          name
          logo {
            image {
              relativePath
            }
          }
          jobs {
            title
            location
            salary
            description
            applicationUrl
          }
        }
        allJobs {
          html
        }
      }
    }
  }
`
